import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"

const useStyles = makeStyles({
  card: {
    minWidth: 300,
    // backgroundColor: 'white',
    marginTop: "40px",
    marginBottom: "70px",
    borderTopRightRadius: "25px",
    borderBottomRightRadius: "25px",
    // '&:hover': {
    //   textDecoration: 'none',
    //   opacity: 0.5,
    // },
    paddingBottom: "10px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    paddingTop: "20px",
    // color: '#565A56',
  },
  clinicText: {
    marginTop: 22,
    marginBottom: 4,
    color: "#dfdfdf",
  },
  hashtags: {
    // color: '#565A56',
    // color: '#565A56',
    fontWeight: "bold",
    color: "#929B91",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    ["@media (max-width:780px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
    },
  },
  clinicButton: {
    color: "#fff!important",
    fontSize: "10px!important",
    backgroundColor: "#929B91!important",
    borderRadius: "20px!important",
    padding: "1.0em 4em!important",
    textDecoration: "none!important",
    alignSelf: "flex-end!important",
    "&:hover": {
      textDecoration: "none!important",
      opacity: 0.5,
      backgroundColor: "#929B91!important",
    },
    ["@media (max-width:780px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block!important",
      marginTop: "12px!important",
    },
    // [theme.breakpoints.down('xs')]: {
    //   marginTop: '24px',
    //   marginBottom: '24px',
    // },
  },
})

const ClinicCard = ({
  city,
  text,
  hashtags,
  address,
  borderLeft,
  backgroundColor,
  titleColor,
  addressColor,
}) => {
  const classes = useStyles()

  return (
    <Card className={classes.card} style={{ borderLeft, backgroundColor }}>
      <CardContent>
        <Container>
          <Typography
            component="h1"
            variant="h4"
            className={classes.title}
            style={{ color: titleColor }}
            gutterBottom
          >
            {city}
          </Typography>
          <Typography className={classes.clinicText}>{text}</Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.hashtags}
            color="textSecondary"
          >
            {hashtags}
          </Typography>
        </Container>
        <Container className={classes.flexContainer}>
          <Typography
            className={classes.clinicText}
            style={{ color: addressColor }}
          >
            {address}
          </Typography>
          <CardActions>
            {/* <Button
              className={classes.clinicButton}
              data-leadbox-popup="mQHuJHuDzbYcpvxExeMJ6A"
              data-leadbox-domain="kooldigital.lpages.co"
            >
              Contact
            </Button> */}
            <Button
              href="https://schedule.clinicminds.com/?clinic=a8f5f0f3-1e41-11e9-881b-0a599d16f52a"
              className="clinicminds-scheduler-button"
              style={{
                fontSize: "12px",
                backgroundColor: "#929B91",
                textDecoration: "none",
                fontSize: "14px",
                padding: "14px 50px",
                marginTop: "40px",
                textAlign: "center",
              }}
            >
              Book appointment
            </Button>
          </CardActions>
        </Container>
      </CardContent>
    </Card>
  )
}

export default ClinicCard
